import { AppComponent } from '../app.component';
import { ArticleRow } from '../components/shared-tables/articles/table-article.component';
import { Navigation, NAVIGATION_ITEMS, NavigationItem } from '../services/navigation-cache/navigation-cache.model';

export class TableHelper {
  static getNavigationItemFromStorage(navigationItem: NAVIGATION_ITEMS): NavigationItem[] {
    return TableHelper.getNavigationItem(JSON.parse(sessionStorage.getItem('navigation')) as Navigation, navigationItem);
  }

  static getNavigationItem(navigation: Navigation, navigationItem: NAVIGATION_ITEMS): NavigationItem[] {
    return navigation?.find(item => item[0] === navigationItem)[1].sort(TableHelper.compareFn);
  }

  static mapToArticleRow(item: NavigationItem[]): ArticleRow[] {
    return item?.map((element, index) => {
      return {
        author: element?.author || AppComponent.AUTHOR,
        title: element.title,
        subtitle: element.subtitle,
        date: new Date(Date.parse(element.date)),
        description: element?.description,
        chips: element?.chips,
        url: element?.url,
      } as ArticleRow });
  }

  private static compareFn(a: NavigationItem, b: NavigationItem): number {
    const dateA = new Date(Date.parse(a.date));
    const dateB = new Date(Date.parse(b.date));

    if (dateA !== dateB) { return dateA > dateB ? -1 : 1; }
    if (a.title !== b.title) { return a.title > b.title ? -1 : 1; }
    if (a.author !== b.author) { return a.author > b.author ? -1 : 1; }
    if (a.chapter !== b.chapter) { return a.author > b.author ? -1 : 1; }

    return 0;
  }
}
