import { HomeCard, HomeCardAltLink, HomeCardUrl, HomeNews } from './home.model';

export const bibleCards: (HomeCardAltLink | HomeCardUrl)[] = [
  { title: 'Przekłady biblijne', imgName: 'bible.jpg', altLink: 'biblia/przeklady' },
  { title: 'Wyszukiwarka', imgName: 'bible-search.jpg', altLink: 'biblia/wyszukaj' },
];

export const strongCards: (HomeCardAltLink | HomeCardUrl)[] = [
  { title: 'UBG Stronga', imgName: 'ubg.jpg', altLink: 'strong/ubg' },
  { title: 'Konkordancja', imgName: 'strong-bible.jpg', altLink: 'strong/konkordancja' },
];

export const newsCards: HomeNews[] = [
  { title: 'Czy Chrystus umarł na krzyżu za nasze choroby?', author: 'Kamil Chmielowski', altLink: 'apologetyka/czy-chrystus-umarl-na-krzyzu-za-nasze-choroby' },
  { title: 'Komentarz Pierwszego Listu do Koryntian 3', author: 'Kamil Chmielowski', altLink: 'komentarze/pierwszy-list-do-koryntian-3' },
  { title: 'Czy odrzucić służbę apostoła Pawła?', author: 'Lighthouse Trails', altLink: 'opracowania/czy-odrzucic-sluzbe-apostola-pawla' },
  { title: 'Zakłopotany przez Dzieje Apostolskie 13,48?', author: 'Lee Brainard', altLink: 'opracowania/zaklopotany-przez-dzieje-apostolskie-1348' },
  { title: 'Napominanie, przepraszanie, przebaczanie', author: 'Kamil Chmielowski', altLink: 'opracowania/napominanie-przepraszanie-przebaczanie' },
  { title: 'Błogosławcie Pana!', author: 'Dave Hunt', altLink: 'opracowania/blogoslawcie-pana' },
];

export const studiesCards: (HomeCard | HomeCardAltLink)[] = [
  { title: 'Komentarze', imgName: 'commentaries.jpg' },
  { title: 'Opracowania', imgName: 'elaboration.jpg' },
  { title: 'Apologetyka (pytania)', imgName: 'apologetics.jpg', altLink: 'apologetyka' },
];

export const booksCard: HomeCardAltLink[] = [
  { title: 'Kościół Pielgrzym', imgName: 'books/pilgrim-church.webp', altLink: 'ksiazki/kosciol-pielgrzym' },
  { title: 'Inwazja okultyzmu', imgName: 'books/occult-invasion.jpg', altLink: 'ksiazki/inwazja-okultyzmu' },
  { title: '', imgName: 'books/psychology-and-the-church.webp', altLink: 'ksiazki/psychologia-i-kosciol' },
  { title: '', imgName: 'books/zwiedzione-chrzescijanstwo.webp', altLink: 'ksiazki/zwiedzione-chrzescijanstwo' },
  { title: '', imgName: 'books/the-chosen.jpg', altLink: 'opracowania/the-chosen-10-kluczowych-obaw' },
];

export const musicCard: HomeCardUrl[] = [
  { title: 'Śpiewnik Pielgrzyma', imgName: 'spiewnik-pielgrzyma.webp', url: 'https://spiewnikpielgrzyma.pl/' },
  { title: 'Bezpieczna Przystań', imgName: 'bezpieczna-przystan.webp', url: 'https://bezpiecznaprzystan.net/' },
];

export const otherCards: (HomeCardAltLink | HomeCardUrl)[] = [
  { title: 'Potomkowie Adama', imgName: 'adam-generation.jpg', altLink: 'opracowania/potomkowie-adama' },
  { title: 'Linia czasowa potopu', imgName: 'flood.jpg', altLink: 'opracowania/globalny-potop' },
];
